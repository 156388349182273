<template>
  <!-- 添加编辑直播间 -->
  <el-main>
    <el-tabs v-model="step">
      <el-tab-pane label="基本信息" name="first">
        <!-- 第一步的表单填写 -->
        <div class="stepOneBox">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="150px"
          >
            <el-form-item prop="title" label="直播间名称:">
              <div class="inputBox">
                <el-input v-model="ruleForm.title"></el-input>
                <div class="tips">长度必须在3-17个汉字(6-34字节)</div>
              </div>
            </el-form-item>
            <el-form-item prop="time" label="开播时间:">
              <div class="inputBox">
                <el-date-picker
                  v-model="ruleForm.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <div class="tips" style="line-height: 20px">
                  直播计划开始时间（开播时间需要在当前时间的10分钟后 并且 开始时间不能在 6
                  个月后）<br />直播计划结束时间（开播时间和结束时间间隔不得短于30分钟，不得超过24小时）
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="nick_name" label="主播昵称:">
              <div class="inputBox">
                <el-input v-model="ruleForm.nick_name"></el-input>
                <div class="tips">长度必须在2-15个汉字(4-30字节)</div>
              </div>
            </el-form-item>
            <!-- <el-form-item prop="name">
              <div class="inputBox">
                <div class="title">主播简介：</div>
                <el-input v-model="ruleForm.name"></el-input>
                <div class="tips">15个字以内</div>
              </div>
            </el-form-item> -->
            <el-form-item prop="wechat" label="主播微信号:">
              <div class="inputBox">
                <el-input v-model="ruleForm.wechat"></el-input>
                <div class="tips">每个直播间需要绑定一个用作核实主播身份，不会展示给观众。</div>
              </div>
            </el-form-item>
            <el-form-item label="副主播微信号:">
              <div class="inputBox">
                <el-input v-model="ruleForm.sub_wechat"></el-input>
                <div class="tips">
                  直播时，主播副号能在主播端进行推送商品，抽奖等操作。仅从主播端小程序进入时，副号身份才生效。若从观众端进入，副号身份不生效
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="name" label="官方收录:">
              <div class="inputBox">
                <el-radio-group v-model="ruleForm.is_feeds_public">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
                <div class="tips">开启后本场直播将有可能被官方推荐</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="直播间配置" name="second">
        <div class="shareImg">
          <div class="imgBox shareImgBox">
            <div class="shareApp">
              <img :src="appInfo.logo" alt="" />
              {{ appInfo.name }}
            </div>
            <div class="showUploadImg">
              <img v-if="ruleForm.share_img_url" :src="ruleForm.share_img_url" alt="" />
            </div>
          </div>
          <div class="uploadImg">
            <div class="title"><span style="color: red">*</span>分享卡片封面：</div>
            <div class="contentBox">
              <div class="tips">
                <p>用户在微信对话框内分享的直播间将以分享卡片的形式呈现。</p>
                <p>建议像素800*640，大小不超过1M</p>
              </div>
              <ReadyUploadSource
                :path="ruleForm.share_img_url"
                :showStyle="{ width: '200px', height: '160px' }"
                :url="2"
                type="live"
                @getSource="getImgShare"
                @removeThis="removeImgShare"
                :maxSize="1024 * 1024"
              ></ReadyUploadSource>
            </div>
          </div>
        </div>
        <div class="coverImg">
          <div class="imgBox coverImgBox">
            <img src="@/assets/image/liveRoom/cardBg.png" alt="" />
            <div class="appInfo">
              <img :src="appInfo.logo" alt="" />
              {{ appInfo.name }}
            </div>
            <div class="liveName">{{ ruleForm.title }}</div>
            <div class="uploadImgBox" v-if="ruleForm.cover_img_url" :style="coverStyle"></div>
          </div>
          <div class="uploadImg">
            <div class="title"><span style="color: red">*</span>直播卡片封面：</div>
            <div class="contentBox">
              <div class="tips">
                <p>图片建议大小为800*800像素。</p>
                <p>图片大小不超过100k。</p>
                <p>图片内容遵循平台规范后更容易被推荐</p>
              </div>
              <ReadyUploadSource
                :path="ruleForm.cover_img_url"
                :showStyle="{ width: '150px', height: '150px' }"
                :url="2"
                type="live"
                @getSource="getImgCover"
                @removeThis="removeImgCover"
                :maxSize="1024 * 100"
              ></ReadyUploadSource>
            </div>
          </div>
        </div>
        <div class="liveBackgroundImg">
          <div class="imgBox liveImgBox">
            <img src="@/assets/image/liveRoom/liveBg.png" alt="" />
            <div class="appInfo">
              <img :src="appInfo.logo" alt="" />
              {{ appInfo.name }}
            </div>
            <div class="uploadBox" :style="feedsStyle"></div>
          </div>
          <div class="contentBigBox">
            <div class="uploadImg">
              <div class="title"><span style="color: red">*</span>直播背景墙封面：</div>
              <div class="contentBox">
                <div class="tips">
                  <p>直播间背景墙是每个直播间的默认背景图。</p>
                  <p>建议像素1080*1920，大小不超过2M</p>
                </div>
                <ReadyUploadSource
                  :path="ruleForm.feeds_img_url"
                  :showStyle="{ width: '108px', height: '192px' }"
                  :url="2"
                  type="live"
                  @getSource="getImgFeeds"
                  @removeThis="removeImgFeeds"
                  :maxSize="1024 * 1024 * 2"
                ></ReadyUploadSource>
              </div>
            </div>
            <div class="uploadImg">
              <div class="title"><span style="color: red">*</span>直播间功能：</div>
              <div class="contentBox">
                <div class="functionBox">
                  <div class="tips">以下四个功能无法在开播后设置开启或关闭</div>
                  <div class="checkBox">
                    <el-checkbox
                      :disabled="isUpdate"
                      v-model="ruleForm.close_comment"
                      :true-label="0"
                      :false-label="1"
                      label="评论"
                    ></el-checkbox>
                    <el-checkbox
                      :disabled="isUpdate"
                      v-model="ruleForm.close_goods"
                      :true-label="0"
                      :false-label="1"
                      label="商品货架"
                    ></el-checkbox>
                    <el-checkbox
                      :disabled="isUpdate"
                      v-model="ruleForm.close_share"
                      :true-label="0"
                      :false-label="1"
                      label="分享"
                    ></el-checkbox>
                    <el-checkbox
                      :disabled="isUpdate"
                      v-model="ruleForm.close_like"
                      :true-label="0"
                      :false-label="1"
                      label="点赞"
                    ></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="uploadImg">
              <div class="title"><span style="color: red">*</span>拓展功能：</div>
              <div class="contentBox">
                <div class="functionBox">
                  <div class="checkBox">
                    <el-checkbox
                      v-model="ruleForm.close_replay"
                      :true-label="0"
                      :false-label="1"
                      label="回放"
                    ></el-checkbox>
                    <el-checkbox
                      v-model="ruleForm.close_kf"
                      :true-label="0"
                      :false-label="1"
                      label="客服"
                    ></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <Preservation @preservation="preservation" :activeName="step" @nextOr="nextOr"></Preservation>
    <el-dialog :visible.sync="errcodeFlag">
      <div style="text-align: center; font-size: 20px">
        <p>
          请使用微信号<span style="color: red">{{
            errcode === '300039' ? ruleForm.sub_wechat : ruleForm.wechat
          }}</span
          >扫码进行授权
        </p>
        <img :src="errcodeImg" alt="" />
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation.vue'
import ReadyUploadSource from '@/components/readyUploadSource'
export default {
  components: {
    Preservation,
    ReadyUploadSource
  },
  data() {
    function checkTitle(rule, value, callback) {
      let len = value.replace(/[^\u0000-\u00ff]/g, 'aa').length
      if (len >= 6 && len <= 34) {
        callback()
      } else {
        callback(new Error('请按要求长度范围输入信息'))
      }
    }
    function checkTime(rule, value, callback) {
      if (!value || !value.length) {
        callback(new Error('开播时间不能为空'))
        return
      }
      let startTime = value[0].getTime()
      let endTime = value[1].getTime()
      let now = new Date().getTime()
      let tenMinThen = now + 1000 * 60 * 10
      let thirtyMin = 1000 * 60 * 30
      let hour24 = 1000 * 60 * 60 * 24
      if (startTime < tenMinThen) {
        callback(new Error('开播时间必须在当前时间的十分钟后'))
      } else if (endTime - startTime < thirtyMin) {
        callback(new Error('开播时间和结束时间间隔不得短于30分钟'))
      } else if (endTime - startTime > hour24) {
        callback(new Error('开播时间和结束时间间隔不得超过24小时'))
      } else {
        callback()
      }
    }
    function checkNick(rule, value, callback) {
      let len = value.replace(/[^\u0000-\u00ff]/g, 'aa').length
      if (len >= 4 && len <= 30) {
        callback()
      } else {
        callback(new Error('请按要求长度范围输入信息'))
      }
    }
    function checkWechat(rule, value, callback) {
      if (!value) {
        callback(new Error('主播微信号不能为空'))
      } else {
        callback()
      }
    }
    return {
      step: 'first',
      ruleForm: {
        title: '',
        cover_img: '',
        cover_img_url: '',
        nick_name: '',
        wechat: '',
        sub_wechat: '',
        // creater_wechat: '',
        time: [],
        share_img: '',
        share_img_url: '',
        feeds_img: '',
        feeds_img_url: '',
        is_feeds_public: 1,
        type: 0,
        close_like: 0,
        close_goods: 0,
        close_comment: 0,
        close_share: 0,
        close_replay: 0,
        close_kf: 0
      },
      rules: {
        title: { required: true, validator: checkTitle, trigger: 'blur' },
        time: { required: true, validator: checkTime, trigger: 'blur' },
        nick_name: { required: true, validator: checkNick, trigger: 'blur' },
        wechat: { required: true, validator: checkWechat, trigger: 'blur' }
      },
      appInfo: {},
      errcode: '',
      errcodeFlag: false,
      errcodeImg: '',
      isUpdate: false,
      pickerOptions: {
        disabledDate: (res) => {
          let time = res.getTime()
          let now = new Date().getTime() - 1000 * 60 * 60 * 24
          let sixThen = new Date().getTime() + 1000 * 60 * 60 * 24 * 30 * 6
          if (time >= now && time <= sixThen) {
            return false
          } else {
            return true
          }
        }
      },
      timeIsOk: false,
      timeTips: '',
      feedsStyle: {
        backgroundImage: ''
      },
      coverStyle: {
        backgroundImage: ''
      }
    }
  },
  mounted() {
    let room_id = this.$route.query.room
    if (room_id) {
      this.isUpdate = true
      this.getRoomInfo(room_id)
    } else {
      let start = Date.now() + 1000 * 60 * 15
      let end = start + 1000 * 60 * 60 * 24 - 1
      let arr = [new Date(start), new Date(end)]
      this.$set(this.ruleForm, 'time', arr)
    }
    this.getAppInfo()
  },
  methods: {
    // 上一步或者下一步
    nextOr() {
      if (this.step == 'first') {
        this.step = 'second'
      } else {
        this.step = 'first'
      }
    },
    // 根据直播间id查询直播间信息
    getRoomInfo(room_id) {
      this.$axios
        .post(this.$api.push.liveRoom.getInfo, {
          room_id
        })
        .then((res) => {
          if (res.code === 0) {
            this.ruleForm = res.result
            let arr = [new Date(res.result.starttime * 1000), new Date(res.result.endtime * 1000)]
            this.$set(this.ruleForm, 'time', arr)
            if (this.isUpdate) {
              console.log(res.result)
              this.$set(this.feedsStyle, 'backgroundImage', `url(${res.result.feeds_img_url})`)
              this.$set(this.coverStyle, 'backgroundImage', `url(${res.result.cover_img_url})`)
            }
          }
        })
    },
    // 获取小程序的头像和名称
    getAppInfo() {
      this.$axios
        .post(this.$api.push.liveRoom.getAppData, {
          type: 1
        })
        .then((res) => {
          if (res.code === 0) {
            this.appInfo = res.result
          }
        })
    },
    getImgFeeds(val) {
      this.feedsStyle.backgroundImage = `url("${val.path}")`
      this.ruleForm.feeds_img = val.media_id
      this.ruleForm.feeds_img_url = val.path
    },
    removeImgFeeds() {
      this.feedsStyle.backgroundImage = ''
      this.ruleForm.feeds_img = ''
      this.ruleForm.feeds_img_url = ''
    },
    getImgCover(val) {
      this.coverStyle.backgroundImage = `url("${val.path}")`
      this.ruleForm.cover_img = val.media_id
      this.ruleForm.cover_img_url = val.path
    },
    removeImgCover() {
      this.coverStyle.backgroundImage = ''
      this.ruleForm.cover_img = ''
      this.ruleForm.cover_img_url = ''
    },
    getImgShare(val) {
      this.ruleForm.share_img = val.media_id
      this.ruleForm.share_img_url = val.path
    },
    removeImgShare() {
      this.ruleForm.share_img = ''
      this.ruleForm.share_img_url = ''
    },
    // 保存配置
    preservation() {
      let that = this
      let room_id = this.$route.query.room
      if (room_id) {
        this.ruleForm.id = room_id
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.cover_img_url) {
            this.$message.warning('请上传直播卡片封面')
            return
          }
          if (!this.ruleForm.share_img_url) {
            this.$message.warning('请上传分享卡片封面')
            return
          }
          if (!this.ruleForm.feeds_img_url) {
            this.$message.warning('请上传直播间背景图')
            return
          }
          let obj = JSON.parse(JSON.stringify(this.ruleForm))
          obj.starttime = Math.ceil(new Date(obj.time[0]).getTime() / 1000)
          obj.endtime = Math.ceil(new Date(obj.time[1]).getTime() / 1000)
          delete obj.time
          this.$axios
            .post(
              !room_id ? this.$api.push.liveRoom.liveRoomAdd : this.$api.push.liveRoom.liveRoomEdit,
              obj
            )
            .then((res) => {
              if (res.code === 0) {
                if (room_id) {
                  that.$message.success('编辑成功')
                } else {
                  that.$message.success('添加成功')
                }
                that.$router.push('/extension/appletLiveBroadcast')
              } else if (res.code === -1) {
                that.$message.error(res.msg)
              } else {
                if (res.result.errcode == '300039') {
                  that.$message.error('副微信号没有进行直播验证')
                  that.errcode = res.result.errcode
                  that.errcodeImg = res.result.qrcode_url
                  that.errcodeFlag = true
                } else if (res.result.errcode == '300036') {
                  that.$message.error('主播微信号没有进行直播验证')
                  that.errcode = res.result.errcode
                  that.errcodeImg = res.result.qrcode_url
                  that.errcodeFlag = true
                } else {
                  that.$message.error(res.msg)
                }
              }
            })
        } else {
          // 检测失败
          this.step = 'first'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  height: 100%;

  .stepOneBox {
    height: 100%;
    padding-bottom: 60px;
    min-width: 1000px;
    .inputBox {
      height: 100%;
      display: flex;
      align-items: center;

      .el-input {
        width: 400px;
      }
      .tips {
        // max-width: 400px;
        height: 40px;
        line-height: 40px;
        color: #999;
        margin-left: 20px;
      }
    }
  }
  .shareImg,
  .coverImg,
  .liveBackgroundImg {
    display: flex;
    margin-bottom: 40px;
    min-width: 1000px;
    .shareImgBox {
      background-image: url('../../../../assets/image/liveRoom/shareBg.png');
      position: relative;
      height: 350px;
      .shareApp {
        position: absolute;
        top: 30px;
        left: 80px;
        display: flex;
        align-items: center;
        font-size: 12px;
        img {
          width: 30px;
          height: 30px;
        }
      }
      .showUploadImg {
        position: absolute;
        top: 100px;
        left: 80px;
        background-color: #ccc;
        width: 230px;
        height: 185px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .coverImgBox {
      position: relative;
      height: 203px;
      & > img {
        position: relative;
        z-index: 2;
      }
      .appInfo {
        display: flex;
        align-items: center;
        position: absolute;
        top: 25px;
        left: 214px;
        font-size: 12px;
        z-index: 3;
        img {
          width: 30px;
          height: 30px;
        }
      }
      .uploadImgBox {
        width: 185px;
        height: 175px;
        position: absolute;
        top: 15px;
        left: 13px;
        background-size: 100% 100%;
        z-index: 1;
      }
      .liveName {
        position: absolute;
        top: 65px;
        left: 214px;
        z-index: 3;
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    .liveImgBox {
      position: relative;
      & > img {
        position: relative;
        z-index: 2;
      }
      .appInfo {
        position: absolute;
        top: 52px;
        left: 55px;
        z-index: 2;
        color: #fff;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
      .uploadBox {
        width: 375px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-size: 100% 100%;
        background-color: #ccc;
      }
    }
    .imgBox {
      width: 375px;
      margin-right: 50px;
    }
    .uploadImg {
      display: flex;
    }
    .tips {
      margin-bottom: 20px;
      font-size: 14px;
    }
    .contentBigBox {
      .uploadImg {
        margin-bottom: 40px;
      }
      .functionBox {
        .checkBox {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.tips {
  color: #ccc;
}
</style>
